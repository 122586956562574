@tailwind base;
@tailwind components;
@tailwind utilities;

/* Dark theme (default) */
:root {
  --theme-bg-color: rgba(16 18 27 / 40%);
  --border-color: rgba(113 119 144 / 25%);
  --theme-color: #f9fafb;
  --inactive-color: rgb(113 119 144 / 78%);
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(12 15 25 / 30%);
  --content-title-color: #999ba5;
  --content-bg: rgb(146 151 179 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: #21242d;
  --dropdown-hover: rgb(42 46 60);
  --popup-bg: rgb(22 25 37);
  --search-bg: #14162b;
  --overlay-bg: rgba(36, 39, 59, 0.3);
  --scrollbar-bg: rgb(1 2 3 / 40%);
}

/* Light theme */
.light-mode {
  --theme-bg-color: rgb(255 255 255 / 31%);
  --border-color: rgb(209 213 219 / 35%);
  --theme-color: #1f2937;
  --inactive-color: #6b7280;
  --body-font: "Poppins", sans-serif;
  --hover-menu-bg: rgba(255 255 255 / 35%);
  --content-title-color: #6b7280;
  --content-bg: rgb(255 255 255 / 13%);
  --button-inactive: rgb(249 250 251 / 55%);
  --dropdown-bg: rgb(255 255 255 / 95%);
  --dropdown-hover: rgb(236 236 236);
  --popup-bg: rgb(255 255 255);
  --search-bg: rgb(255 255 255 / 95%);
  --overlay-bg: rgb(255 255 255 / 30%);
  --scrollbar-bg: rgb(209 213 219 / 40%);
}

/* Diğer stiller aynı kalabilir */

* {
  box-sizing: border-box;
  outline: none;
}

body {
  margin: 0;
  background-color: var(--theme-bg-color);
  color: var(--theme-color);
  font-family: var(--body-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--scrollbar-bg);
  border-radius: 10px;
}

/* Video Background Styles */
.video-bg {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.video-bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Light Mode Styles */
.light-mode {
  --theme-bg-color: rgb(255 255 255 / 31%);
  --theme-color: #3c3a3a;
  --inactive-color: #333333;
  --button-inactive: #3c3a3a;
  --search-bg: rgb(255 255 255 / 31%);
  --dropdown-bg: #f7f7f7;
  --overlay-bg: rgb(255 255 255 / 30%);
  --dropdown-hover: rgb(236 236 236);
  --border-color: rgb(255 255 255 / 35%);
  --popup-bg: rgb(255 255 255);
  --hover-menu-bg: rgba(255 255 255 / 35%);
  --scrollbar-bg: rgb(255 253 253 / 57%);
  --content-title-color: --theme-color;
}

/* Poppins Font Import */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

/* Responsive Styles */
@media screen and (max-width: 480px) {
  body {
    padding: 0.8em;
  }
}

@media screen and (max-width: 945px) {
  .left-side {
    display: none;
  }
}

@media screen and (max-width: 1210px) {
  .right-side {
    display: none;
  }
}